<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Editar': 'Adicionar' }} Serviço
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmitLocal)"
            @reset.prevent="resetForm"
          >
            <!-- Physician -->
            <validation-provider
              v-if="currentPersonType === 'PF'"
              #default="validationContext"
              name="Médico"
              rules="required"
            >
              <b-form-group
                label="Médico"
                label-for="physician"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.physicianId"
                  :options="calendarOptions"
                  label="label"
                  :reduce="value => value.physician.id"
                  :selectable="(value) => !value.physician.schedule_restriction"
                  input-id="physician"
                  :disabled="readOnly"
                >
                  <template #option="{ color, fullLabel, physician }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span class="text-truncate d-inline-block" style="max-width: 300px">
                      {{ fullLabel }}
                    </span>
                    <div class="text-secondary ml-2 text-truncate" style="max-width: 300px">
                      {{ physician.email }}
                    </div>
                  </template>

                  <template #selected-option="{ color, fullLabel }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span class="text-truncate d-inline-block" style="max-width: 280px"> {{ fullLabel }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="currentPersonType === 'PJ'"
              #default="validationContext"
              name="Médico"
            >
              <b-form-group
                label="Médico (PJ)"
                label-for="physician"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="physicianCompany"
                  :filter="filterPhysicianCompany"
                  :options="calendarPjOptions"
                  label="physician_company_id"
                  input-id="physician_company_id"
                  :get-option-key="(d) => d.physician_company_id"
                  :disabled="readOnly"
                  @input="changePhysicianCompany"
                >
                  <template #option="{ name, company_name, company_cnpj }">
                    <span class="text-truncate d-inline-block" style="max-width: 300px">
                      {{ name }}
                    </span>
                    <div class="text-secondary text-truncate" style="max-width: 300px">
                      {{ company_name }} |
                      {{ formatCnpj(company_cnpj) }}
                    </div>
                  </template>

                  <template #selected-option="{ name, company_name }">
                    <span class="text-truncate d-inline-block" style="max-width: 280px">
                      {{ name }} | {{ company_name }}
                    </span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row>
              <b-col cols="12" md="6">
                <!-- Start Date -->
                <validation-provider
                  #default="validationContext"
                  name="Data de início"
                  rules="required"
                >
                  <b-form-group
                    class="-mr-1"
                    label="Data de início"
                    label-for="start-date"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.start"
                      class="form-control input-date"
                      :config="configDate"
                      :class="{'is-invalid': !dateIntervalValid }"
                      :disabled="readOnly"
                      @input="changeStartDate"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <!-- End Date -->
                <validation-provider
                  #default="validationContext"
                  name="Data de fim"
                >
                  <b-form-group
                    class="-ml-1"
                    label="Data de fim"
                    label-for="end-date"
                    :state="getValidationState(validationContext)"
                  >
                    <flat-pickr
                      v-model="eventLocal.end"
                      class="form-control input-date"
                      :config="configDate"
                      :class="{'is-invalid': !dateIntervalValid }"
                      :disabled="readOnly"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <validation-provider
              #default="validationContext"
              name="Serviço"
              vid="type"
            >
              <b-form-group
                label="Serviço"
                label-for="event-duty"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.extendedProps.type"
                  :options="allDutyOptions"
                  :filter="filterDutyOptions"
                  :reduce="v => v.service_type"
                  :clearable="false"
                  label="name"
                  input-id="event-duty"
                  :disabled="readOnly"
                  @input="updateDuty"
                >
                  <template #option="{ name }">
                    <strong class="text-secondary">
                      {{ name }}
                    </strong>

                  </template>
                  <template #selected-option="{ name }">
                    <span class="text-secondary">
                      {{ name }}
                    </span>
                  </template>
                </v-select>

                <div
                  class="text-danger d-none invalid-feedback"
                  :class="{'d-block': validationContext.errors[0]}"
                >
                  {{ validationContext.errors[0] }}
                </div>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="validationContext"
              name="Descrição"
              rules="required"
            >
              <b-form-group label-for="description">
                <label for="description" class="d-block">
                  Descrição
                </label>
                <b-form-textarea
                  id="description"
                  v-model="eventLocal.extendedProps.description"
                  placeholder="Descrição"
                  rows="3"
                  :disabled="readOnly"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Price -->
            <validation-provider
              #default="validationContext"
              name="Valor"
              rules="required|between:1,100000000"
            >
              <b-form-group
                label-for="event-price"
              >
                <label for="event-price" class="d-block">
                  Valor <span v-if="amountPaidText" class="text-danger">({{ amountPaidText }})</span>
                </label>
                <money
                  id="event-price"
                  v-model="eventLocal.extendedProps.amountPaid"
                  autofocus
                  autocomplete="off"
                  class="form-control"
                  v-bind="money"
                  placeholder="R$ 1.000,00"
                  maxlength="20"
                  :disabled="readOnly"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-checkbox
              id="event-confirmed"
              v-model="eventLocal.extendedProps.released"
              :disabled="!eventLocal.extendedProps.physicianId || readOnly"
            >
              Liberar Crédito
            </b-form-checkbox>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="readOnly"
              >
                {{ eventLocal.id ? 'Salvar' : 'Adicionar' }}
              </b-button>
              <b-button
                v-if="eventLocal.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                class="mr-2"
                @click="$emit('remove-physician-duty'); hide();"
              >
                Remover
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormCheckbox, BButton, BFormInvalidFeedback, BFormTextarea,
} from 'bootstrap-vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, url, regex, timeDuration,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { Money } from 'v-money'
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  parse, format, isAfter, intervalToDuration, isValid, addMinutes, isWeekend,
} from 'date-fns'
import { formatCnpj } from '@core/utils/utils'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BFormTextarea,
    Money,
    BButton,
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    personType: {
      type: String,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      timeDuration,
      required,
      regex,
      email,
      url,
      configDate: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i',
        locale: Portuguese,
        time_24hr: true,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      options: {
        time: {
          numericOnly: true,
          delimiter: ':',
          blocks: [2, 2],
        },
      },
    }
  },
  methods: { formatCnpj },
  setup(props, { emit }) {
    const readOnly = ref(false)
    const clearFormData = ref(null)
    const dutyOptions = ref([])
    const serviceTypesOptions = ref([])
    const lastDutyModifier = ref(null)

    const {
      eventLocal,
      amountPaidText,
      resetEventLocal,
      calendarOptions,
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const physicianCompany = ref(null)
    const calendarPjOptions = ref([])

    function getStartDateAsDate() {
      if (eventLocal.value.start) { return parse(eventLocal.value.start, 'dd/MM/yyyy HH:mm', new Date()) }
      return null
    }

    watch([eventLocal], () => {
      if (eventLocal.value) {
        const isEqualPhysician = option => option.id === eventLocal.value.extendedProps.physicianId
            && option.physician_company_id === eventLocal.value.extendedProps.physicianCompanyId
        let p = calendarPjOptions.value.find(isEqualPhysician)
        if (!p && eventLocal.value.extendedProps.physicianCompanyId) {
          p = {
            id: eventLocal.value.extendedProps.eventData.physician.id,
            name: eventLocal.value.extendedProps.eventData.physician.name,
            cpf: eventLocal.value.extendedProps.eventData.physician.cpf,
            company_name: eventLocal.value.extendedProps.eventData.physician_company.name,
            company_cnpj: eventLocal.value.extendedProps.eventData.physician_company.cnpj,
            physician_company_id: eventLocal.value.extendedProps.eventData.physician_company.id,
          }
        }
        physicianCompany.value = p

        const start = getStartDateAsDate()
        const typeDate = isWeekend(start) ? 'weekend' : 'week'
        lastDutyModifier.value = typeDate
      }
      // readOnly.value = eventLocal.value.extendedProps && eventLocal.value.extendedProps.integrationCode !== null
    }, { deep: true })

    const currentPersonType = computed(() => {
      const type = eventLocal.value?.extendedProps?.eventData?.person_type
      return type || props.personType
    })
    const isReleased = computed(() => eventLocal && eventLocal.extendedProps && eventLocal.extendedProps.released)
    const allDutyOptions = computed(() => {
      const options = dutyOptions.value
      if (!eventLocal.value) return options

      const { workplaceDuty } = eventLocal.value.extendedProps
      if (workplaceDuty && !options.find(i => i.id === workplaceDuty.id)) {
        return [
          ...(options),
          {
            id: workplaceDuty.id,
            name: workplaceDuty.duty_type,
            complement: workplaceDuty.complement,
            service_type: workplaceDuty.type,
          },
        ]
      }

      return options
    })

    function getRemunerationType(type) {
      if (type === 'week') return 'Semana'
      if (type === 'weekend') return 'Final semana'
      return ''
    }

    store.dispatch('app-workplace/fetchWorkplace', { id: router.currentRoute.params.id })
      .then(({ data }) => {
        dutyOptions.value = data.data.duties.filter(d => !!d.service_type).map(duty => ({
          id: duty.id,
          name: duty.duty_type,
          complement: duty.complement,
          service_type: duty.service_type,
        }))
      })

    store.dispatch('physician-duty/fetchPhysiciansPj', { id: router.currentRoute.params.id })
      .then(({ data }) => {
        calendarPjOptions.value = data.data
      })

    function changePhysicianCompany(value) {
      if (value) {
        eventLocal.value.extendedProps.physicianId = value.id
        eventLocal.value.extendedProps.physicianCompanyId = value.physician_company_id
      } else {
        eventLocal.value.extendedProps.physicianId = null
        eventLocal.value.extendedProps.physicianCompanyId = null
        eventLocal.value.extendedProps.released = false
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    function timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number)
      return hours * 60 + minutes
    }

    function updateValue() {
      //
    }

    function updateDuty() {
      updateValue()
    }

    function changeStartDate() {
      updateValue()
    }

    function currency(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    const intervalLess24 = (start, end) => {
      if (!start || !end) return true
      const startDate = parse(start, 'dd/MM/yyyy HH:mm', new Date())
      const endDate = parse(end, 'dd/MM/yyyy HH:mm', new Date())
      if (!isValid(startDate) || !isValid(endDate)) return true
      if (isAfter(endDate, startDate)) {
        const inter = intervalToDuration({ start: startDate, end: endDate })
        return (inter?.days * 24 + inter?.hours) <= 24
      }
      return true
    }

    const dateIntervalValid = computed(() => {
      const { start, end } = eventLocal.value
      if (!start || !end) return true
      const startDate = parse(start, 'dd/MM/yyyy HH:mm', new Date())
      const endDate = parse(end, 'dd/MM/yyyy HH:mm', new Date())
      return isAfter(endDate, startDate) && intervalLess24(start, end)
    })

    const dateIntervalLess24 = computed(() => {
      const { start, end } = eventLocal.value
      return intervalLess24(start, end)
    })

    function changeTimeDuration() {
      const time = eventLocal.value.extendedProps.timeInterval
      if (time.length !== 5) return
      if (typeof (eventLocal.value.start) === 'string' && time) {
        const minutes = timeToMinutes(eventLocal.value.extendedProps.timeInterval)
        // const start = parse(eventLocal.value.start, 'dd/MM/yyyy HH:mm', new Date())
        const { start } = eventLocal.value
        eventLocal.value.end = format(addMinutes(start, minutes), 'dd/MM/yyyy HH:mm')
      }

      updateValue()
    }

    function filterDutyOptions(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name', 'duration', 'remuneration'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    function filterPhysicianCompany(options, search) {
      const fuse = new Fuse(options, {
        keys: ['company_cnpj', 'company_name', 'cpf', 'email', 'name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    }

    function onSubmitLocal() {
      // const eventData = JSON.parse(JSON.stringify(eventLocal))
      // if (props.personType === 'PJ' && !eventData.value.extendedProps.type) {
      //   refFormObserver.value.setErrors({
      //     type: ['Campo Serviço é obrigatório'],
      //   })
      //   return false
      // }
      return onSubmit()
    }

    return {
      readOnly,
      amountPaidText,
      eventLocal,
      isReleased,
      dutyOptions,
      allDutyOptions,
      calendarOptions,
      dateIntervalLess24,
      onSubmit,
      changeStartDate,

      resetForm,
      refFormObserver,
      getValidationState,
      currency,
      updateDuty,

      dateIntervalValid,
      serviceTypesOptions,
      filterDutyOptions,
      calendarPjOptions,
      changePhysicianCompany,
      physicianCompany,
      currentPersonType,
      filterPhysicianCompany,
      onSubmitLocal,
      changeTimeDuration,
      getRemunerationType,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-muted {
  color: #898993!important;
}
</style>

// Full Calendar Plugins
import { isEqual } from 'lodash'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import {
  ref, computed, watch,
} from '@vue/composition-api'
import store from '@/store'
import calendarApi from '@/views/physician-duty-services/calendar-api'
import { formatCnpj, formatCurrencyInt, truncateString } from '@core/utils/utils'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)
  const calendarFilters = {
    startDate: null,
    endDate: null,
  }

  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    id: '',
    title: '',
    color: '',
    start: '',
    end: '',
    extendedProps: {
      physicianId: '',
      timeInterval: '',
      workplaceDutyId: '',
      type: '',
      amountPaid: '',
      released: false,
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const getColor = eventData => {
    if (eventData.confirmed) return 'rgba(33,197,98,0.55)'
    if (eventData.released) return 'rgba(171,86,209,0.73)'
    return 'rgba(42,42,42,0.25)'
  }

  const parseEvent = eventData => {
    const isFilled = eventData.physician
    const duty = '(Serviço sem médico)'
    let title = isFilled ? `<strong>Médico</strong>: ${eventData.physician.name}` : duty
    if (eventData.physician_company && eventData.physician_company.name) title += ` <br> <strong>${eventData.physician_company.name}</strong> | ${formatCnpj(eventData.physician_company.cnpj)}`
    const start = new Date(`${eventData.start_date}-03:00`)
    const end = new Date(`${eventData.end_date}-03:00`)
    return {
      id: eventData.id,
      title,
      subtitle: `<strong>Tipo</strong>: ${eventData.translate_type}`,
      subtitle2: `<strong class="ml-1">Valor</strong>: ${formatCurrencyInt(eventData.amount_paid)}`,
      content: `<strong>Descrição</strong>: ${truncateString(eventData.description, 20)}`,
      color: getColor(eventData),
      start,
      end,
      extendedProps: {
        eventData: { ...eventData },
        physicianId: eventData.physician ? eventData.physician.id : '',
        physicianCompanyId: eventData.physician_company ? eventData.physician_company.id : '',
        integrationCode: eventData.integration_code,
        amountPaid: eventData.amount_paid / 100,
        confirmed: eventData.confirmed,
        released: eventData.released,
        serviceType: eventData.service_type,
        type: eventData.type,
        description: eventData.description,
      },
    }
  }

  const updatePhysicianDutyInCalendar = () => {
    refetchEvents()
    toast({
      component: ToastificationContent,
      props: {
        title: 'Serviço Atualizado',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  }

  const removePhysicianDutyInCalendar = () => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Serviço removido',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
  }

  const parsePhysicianDuty = eventData => {
    const { currentWorkplaceId: workplaceId } = store.state['physician-duty']
    const {
      id,
      start,
      end,
      extendedProps: {
        personType, serviceType, type, description, physicianCompanyId, physicianId, workplaceDutyId, workplaceDuty, amountPaid, integrationCode, confirmed, released,
      },
    } = eventData
    return {
      id,
      start,
      end,
      personType,
      physicianCompanyId,
      physicianId,
      workplaceDutyId,
      workplaceDuty,
      workplaceId,
      integrationCode,
      amountPaid,
      confirmed,
      released,
      description,
      type,
      serviceType,
    }
  }
  const addPhysicianDuty = eventData => {
    store.dispatch('physician-duty/addPhysicianDuty', parsePhysicianDuty(eventData)).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    }).catch(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.response.data.message || 'Erro ao atualizar',
          icon: 'CloseIcon',
          variant: 'danger',
        },
      })
    })
  }

  const updatePhysicianDuty = eventData => {
    store.dispatch('physician-duty/updatePhysicianDuty', parsePhysicianDuty(eventData)).then(response => {
      const updatedEvent = parseEvent(response.data.data)

      const propsToUpdate = ['id', 'title', 'color']
      const extendedPropsToUpdate = ['eventData', 'personType', 'timeInterval', 'physicianCompanyId', 'physicianId', 'type', 'serviceType', 'amountPaid', 'integrationCode', 'confirmed', 'released']

      updatePhysicianDutyInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message || 'Erro ao atualizar',
            icon: 'CloseIcon',
            variant: 'danger',
          },
        })
      })
  }

  const removePhysicianDuty = () => {
    const eventId = event.value.id
    store.dispatch('physician-duty/removePhysicianDuty', { id: eventId }).then(() => {
      removePhysicianDutyInCalendar(eventId)
    })
  }

  let lastDutyTypeParams = {}
  const fetchDutyTypes = () => {
    const { startDate, endDate } = calendarFilters
    const workplaceId = store.state['physician-duty'].currentWorkplaceId
    const params = {
      workplace_id: workplaceId,
      start_date: startDate,
      end_date: endDate,
    }
    if (isEqual(lastDutyTypeParams, params)) {
      lastDutyTypeParams = params
      return
    }
    lastDutyTypeParams = params
    store.commit('physician-duty/SET_SELECTED_SERVICE_TYPES', null)

    store
      .dispatch('physician-duty/fetchDutyTypeByWorkplace', { workplace_id: workplaceId })
      // .dispatch('physician-duty/fetchDutyTypeInSchedule', params)
      .then(response => {
        store.commit('physician-duty/SET_SERVICE_TYPES', response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar médicos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state['physician-duty'].selectedCalendars)
  const selectedDutyTypes = computed(() => store.state['physician-duty'].selectedDutyTypes)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  watch(selectedDutyTypes, () => {
    if (selectedDutyTypes !== null) {
      refetchEvents()
    }
  })

  const fetchPhysicians = () => {
    store
      .dispatch('physician-duty/fetchPhysicians')
      .then(response => {
        store.commit('physician-duty/SET_PHYSICIANS', response.data.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar médicos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchEvents = (info, successCallback) => {
    calendarFilters.startDate = info.startStr
    calendarFilters.endDate = info.endStr

    // Fetch Events from API endpoint
    const workplaceId = store.state['physician-duty'].currentWorkplaceId
    store
      .dispatch('physician-duty/fetchPhysicianDuties', {
        workplaceId,
        start: info.startStr,
        end: info.endStr,
        physicians: selectedCalendars.value,
        serviceTypes: selectedDutyTypes && selectedDutyTypes.value,
      })
      .then(response => {
        const events = response.data.data.map(parseEvent)
        fetchDutyTypes()
        successCallback(events)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar eventos da agenda',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  const calendarOptions = {
    events: fetchEvents,
    eventClick({ event: clickedEvent }) {
      event.value = JSON.parse(JSON.stringify(clickedEvent))
      isEventHandlerSidebarActive.value = true
    },
  }

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    event,
    clearEventData,
    addPhysicianDuty,
    updatePhysicianDuty,
    removePhysicianDuty,
    refetchEvents,
    fetchEvents,
    fetchPhysicians,
    fetchDutyTypes,
    isEventHandlerSidebarActive,
    calendarOptions,
  }
}

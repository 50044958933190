+<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        Adicionar Serviço
      </b-button>
      <div class="mt-2">
        <h5 class="app-label mt-2">
          <span class="align-middle">Filtrar Médicos</span>
        </h5>
        <b-form-group>
          <v-select
            v-model="selectedCalendars"
            multiple
            label="label"
            item-value="id"
            item-text="type"
            :reduce="value => value.id"
            placeholder="Buscar Especialidade"
            input-id="type"
            :options="calendarOptions"
          >
            <template #option="{ label, physician }">
              <strong>{{ label }}</strong>
              <div class="text-secondary">
                {{ formatCpf(physician.cpf, true) }}
              </div>
            </template>
          </v-select>
        </b-form-group>
        <h5 class="app-label mt-2">
          <span class="align-middle">Filtrar Especialidades</span>
        </h5>
        <b-form-group class="mb-10">
          <v-select
            v-model="selectedDutyTypes"
            multiple
            label="label"
            item-value="service_type"
            item-text="type"
            :reduce="value => value.service_type"
            placeholder="Buscar Unidade"
            input-id="type"
            :options="serviceTypesOptions"
          >
            <template #option="{ label }">
              <strong>{{ label }}</strong>
            </template>
          </v-select>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { formatCpf } from '@core/utils/utils'
import { onUnmounted } from '@vue/composition-api'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BButton,
    BFormGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
  },
  setup() {
    const {
      calendarOptions,
      serviceTypesOptions,
      selectedCalendars,
      selectedDutyTypes,
      checkAll,
      currentPhysicians,
    } = useCalendarSidebar()

    onUnmounted(() => {
      selectedCalendars.value = []
    })

    return {
      calendarOptions,
      serviceTypesOptions,
      selectedCalendars,
      selectedDutyTypes,
      checkAll,
      currentPhysicians,
      formatCpf,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.mb-10 {
  margin-bottom: 10rem!important;
}

.vs__dropdown-menu {
  position: relative!important;
}
</style>
